<template lang="pug">
include ../../../configs/template.pug
div.d-flex.flex-wrap.mt-3.text-left
  div.w-100
    +select-validation('body.passport_type', 'passportTypes', 'civilPassport.type', 'nameLang', '["required"]')

  div.w-33
    +field-validation('body.last_name_ukr', 'lastNameUK', '["alphaUA", "required"]')
  div.w-33
    +field-validation('body.first_name_ukr', 'firstNameUK', '["alphaUA", "required"]')
  div.w-33
    +field('body.middle_name_ukr', '"middleNameUK"')
  div(v-if="body.passport_type === 2").w-50
    +field-validation('body.last_name_eng', 'lastNameEN', '["alphaEN", "required"]')
  div(v-if="body.passport_type === 2").w-50
    +field-validation('body.first_name_eng', 'firstNameEN', '["alphaEN", "required"]')
  div.w-50
    +select-validation('body.sex', 'listSexes','sex', '"value_ukr"','["required"]')(
      :readonly="$route.params?.documentID && ![STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DRAFT,STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.VERIFICATION].includes(this.passport.status_document) && !checkAccess('backOffice')")
  div.w-50
    +select-validation('body.country', 'countriesList', 'citizenship', 'valueLang', '["required"]')
  div.w-50
    +field-validation('body.serial', 'serialAndNum', '["required"]')
  div.w-50
    +date-picker-validation('body.date_start', 'dateIssue', 'dateIssueObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" min="1900-01-01")
  div(v-if="body.passport_type === 2").w-50
    +date-picker-validation('body.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')(
      :min="body.date_start")
  div.w-50
    +field('body.issued_by', '"passportIssued"')

  b.w-100.my-3.pl-3 {{ $t('registrationDoc') }}:

  div.w-30
    +select-validation('body.registration_address.city.country', 'countriesList', 'country', 'valueLang', '["required"]')(return-object)
  div.w-30
    +select-validation('body.registration_address.city.region', 'registrationRegionsList', 'region', 'valueLang', '["required"]')(
      :loading="isRegionLoading" return-object)
  div.w-40
    +select-validation('body.registration_address.city.city', 'registrationCitiesList', 'city', 'valueLang', '["required"]')(
      :loading="isCityLoading")
  div.w-20
    +field-validation('body.registration_address.index', 'placeIndex', '["maxLength", "minLength"]')
  div.w-50
    +field-validation('body.registration_address.street', 'street', '["required"]')
  div.w-15
    +field-validation('body.registration_address.house', 'house', '["required"]')
  div.w-15
    +field('body.registration_address.flat', '"flat"')

  v-checkbox(v-model="sameResidentPlace" :label="sameResidentPlace ? $t('sameRegistration') : $t('notSameRegistration')").w-100

  div(v-if="!sameResidentPlace").d-flex.flex-wrap.w-100
    b.w-100.my-3.pl-3 {{ $t('residentPlace') }}:

    div.w-30
      +select-validation('body.residence_address.city.country', 'countriesList', 'country', 'valueLang', '["required"]')(return-object)
    div.w-30
      +select-validation('body.residence_address.city.region', 'residentRegionsList', 'region', 'valueLang', '["required"]')(
        :loading="isRegionLoading" return-object)
    div.w-40
      +select-validation('body.residence_address.city.city', 'residentCitiesList', 'city', 'valueLang', '["required"]')(
        :loading="isCityLoading")
    div.w-20
      +field-validation('body.residence_address.index', 'placeIndex', '["maxLength", "minLength"]')
    div.w-50
      +field-validation('body.residence_address.street', 'street', '["required"]')
    div.w-15
      +field-validation('body.residence_address.house', 'house', '["required"]')
    div.w-15
      +field('body.residence_address.flat', '"flat"', '["required"]')

  FileDropZone(v-if="checkAccess('civilPassport', 'add_file')" ref="mediaContent").w-100

  div.w-100.d-flex.justify-center.mt-3
    v-btn(@click="saveCitizenPassport" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { citizenPassport } from '@/mixins/validationRules'
import { clearBody } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions, mapMutations } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

const initBody = () => (
  {
    passport_type: null,
    last_name_ukr: null,
    first_name_ukr: null,
    middle_name_ukr: '',
    last_name_eng: null,
    first_name_eng: null,
    sex: null,
    country: null,
    serial: null,
    date_start: null,
    date_end: null,
    issued_by: null,
    registration_address: {
      city: {
        country: null,
        region: null,
        city: null
      },
      index: null,
      street: null,
      house: null,
      flat: null
    },
    residence_address: {
      city: {
        country: null,
        region: null,
        city: null
      },
      index: null,
      street: null,
      house: null,
      flat: null
    }
  }
)

export default {
  name: 'SailorCitizenPassportForm',
  props: {
    passport: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: initBody(),
      registrationRegionsList: [],
      registrationCitiesList: [],
      residentRegionsList: [],
      residentCitiesList: [],
      files: [],
      sameResidentPlace: true,
      isRegionLoading: false,
      isCityLoading: false,
      isLoading: false,
      isTaxNumber: false,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      valueLang: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      countriesList: state => state.directory.country,
      passportTypes: state => state.directory.passportTypes,
      sailorInfo: state => state.sailor.sailorInfo,
      listSexes: state => state.directory.sex

    }),
    dateIssueObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  watch: {
    'body.registration_address.city.country' (country) {
      this.getRegionsList(country, 'city_registration')
    },
    'body.registration_address.city.region' (region) {
      this.getCitiesList(region, 'city_registration')
    },
    'body.residence_address.city.country' (country) {
      this.getRegionsList(country, 'resident')
    },
    'body.residence_address.city.region' (region) {
      this.getCitiesList(region, 'resident')
    }
  },
  validations () { return citizenPassport(this) },
  mounted () {
    this.setFieldsAutocomplete()
  },
  methods: {
    ...mapActions(['setSailorCitizenPassports', 'updateSailorCitizenPassportById', 'getSailorCitizenPassports',
      'getRegion', 'getCity']),
    ...mapMutations(['incrementBadgeCount']),
    getRegionsList (country, type) {
      if (country) {
        this.isRegionLoading = true
        this.getRegion(country?.id || country).then(response => {
          if (type === 'city_registration') this.registrationRegionsList = response.data
          else this.residentRegionsList = response.data
        }).finally(() => {
          this.isRegionLoading = false
        })
      }
    },

    getCitiesList (region, type) {
      if (region) {
        this.isCityLoading = true
        this.getCity(region.id).then(response => {
          if (type === 'city_registration') this.registrationCitiesList = response.data
          else this.residentCitiesList = response.data
        }).finally(() => {
          this.isCityLoading = false
        })
      }
    },

    async saveCitizenPassport () {
      const { documentID } = this.$route.params
      this.sameResidentPlace && (this.body.residence_address = this.body.registration_address)
      let cloneBody = { ...this.body }
      cloneBody.registration_address = { ...this.body.registration_address }
      cloneBody.residence_address = { ...this.body.residence_address }
      if (this.$v.$invalid) return this.$v.$touch()
      let excludeValueInbody = ['country', 'region']

      let files = [...this.$refs.mediaContent.filesArray]
      let data = {
        ...this.$route.params,
        body: clearBody({ ...cloneBody }, ['middle_name_ukr', this.body.passport_type === 2 ? ('last_name_eng', 'first_name_eng') : '']),
        media: {
          files,
          file_type: 'citizen_passport'
        }
      }
      excludeValueInbody.forEach(key => {
        data.body.registration_address && delete data.body.registration_address.city[key]
        data.body.residence_address && delete data.body.residence_address.city[key]
      })
      data.body.registration_address && delete data.body.registration_address.id
      data.body.residence_address && delete data.body.residence_address.id
      if (this.body.registration_address?.city?.city) {
        data.body.registration_address.city = this.body.registration_address.city.city?.id || this.body.registration_address.city.city
      } else data.body.registration_address = null

      this.sameResidentPlace && data.body.registration_address
        ? (data.body.residence_address = data.body.registration_address)
        : this.body.residence_address.city.city
          ? data.body.residence_address.city = this.body.residence_address.city.city?.id || this.body.residence_address.city.city
          : data.body.residence_address = null
      if (!documentID) data.body.status_document = STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DRAFT
      this.isLoading = true
      const response = await this[!documentID ? 'setSailorCitizenPassports' : 'updateSailorCitizenPassportById'](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        if (!documentID) { // If called adding component
          this.$notification.success('civilPassport.added')
          this.$parent.isViewAddSlot = false
          this.body = initBody()
          this.$v.$reset()
        } else this.$notification.success('civilPassport.edited')
        if (this.$route.query?.viewEditBlock) this.$router.replace({ query: {} })
      }
      this.isLoading = false
    },

    setFieldsAutocomplete () {
      if (!this.$route?.params?.documentID && this.$route.name === 'passports-citizen') { // Autocomplete ukrainian full name into civil passport from profile
        ['last_name_ukr', 'first_name_ukr', 'middle_name_ukr'].forEach(value => {
          this.body[value] = this.sailorInfo[value]
        })
      } else if (this.$route.params.documentID) {
        Object.keys(this.body).forEach(key => {
          if (this.passport[key]) {
            if (typeof this.passport[key] === 'object') {
              if (key === 'country') {
                this.body[key] = this.passport[key].id
              } else if (this.passport[key]) {
                Object.keys(this.passport[key]).forEach(key2 => (this.body[key][key2] = this.passport[key][key2]))
              }
            } else this.body[key] = this.passport[key]
          }
          if (['registration_address', 'residence_address'].includes(key)) {
            if (!this.passport[key]) this.body[key] = this.body[key]
          }
        })
      }
      if (this.passport.registration_address?.city?.city.id !== this.passport.residence_address?.city?.city.id) this.sameResidentPlace = false
    }
  }
}
</script>
